import { css } from "@emotion/react"
import styled from "@emotion/styled"
import BasePortableText from "@sanity/block-content-to-react"
import { graphql } from "gatsby"
import camelCase from "lodash/camelCase"
import React from "react"
import { FiMail, FiMapPin, FiSmartphone } from "react-icons/fi"
import clientConfig from "../../client-config"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import TabBody from "../components/TabBody"
import TabHeader from "../components/TabHeader"
import { theme } from "../utils/theme"

const InvestorsRelationsContact = ({ pageContext, data }) => {
  const { langKey } = pageContext
  const title = "Investors Relations Contact"
  const _langKey = camelCase(langKey)
  const { phone, email, address, _rawContent } = data.contact
  const color = data.sanitySiteSettings.color.hex || theme.colors.primary

  return (
    <Layout fluid langKey={langKey}>
      <SEO title={title} />

      <Row>
        <Wrapper>
          <FiSmartphone size={108} color={color} />
          <Title>Phone</Title>
          <span>{phone[_langKey]}</span>
        </Wrapper>
        <Wrapper>
          <FiMail size={108} color={color} />
          <Title>E-mail</Title>
          <span>{email[_langKey]}</span>
        </Wrapper>
        <Wrapper>
          <FiMapPin size={108} color={color} />
          <Title>Office</Title>
          <span>{address[_langKey]}</span>
        </Wrapper>
      </Row>

      <Container>
        <TabHeader title="證卷登記處" />
        <TabBody>
          <BasePortableText
            css={css`
              line-height: 1.9rem;
            `}
            blocks={_rawContent[_langKey]}
            {...clientConfig.sanity}
          />
        </TabBody>
      </Container>
    </Layout>
  )
}

export default InvestorsRelationsContact

export const query = graphql`
  query IRenPageQuery {
    contact: sanityIrContact(_id: { regex: "/irContact/" }) {
      id
      phone {
        zhHant
        en
      }
      email {
        zhHant
        en
      }
      address {
        zhHant
        en
      }
      _rawContent
    }

    sanitySiteSettings {
      color {
        hex
      }
    }
  }
`

const Wrapper = styled.div`
  width: calc(100% / 3);
  margin: 2rem 0;
  padding: 0 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 4rem;
`
const Container = styled.div`
  margin: 2rem auto 5rem auto;
  padding: 2rem;
  min-height: 20vh;
  width: 80%;
`
const Title = styled.span`
  font-size: 1.5rem;
  margin: 1rem 0 0.5rem;
`
